import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Injectable } from '@angular/core';
import {HttpClient, HttpRequest, HttpEvent,HttpHeaders} from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Constants} from '../constant/constants';
import { ApiEndpointConstant } from 'src/app/constant/apiEndpoints-constants';
import { Urls } from 'src/app/constant/urls';
import {ConfirmationService} from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { GeneralFunction } from '../common/general-function';
import { ApiCallingService } from 'src/app/service/api/api-calling.service';
import { ResponseHandlerService } from 'src/app/service/response-handler.service';
import { MessageService } from 'primeng/api';
import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  
  errorMsg: any;
  success: Boolean = false;
  sessionName: any;
  errorMsgData: any;
  isInvalid = false;
  validationErrors: any;

  getIsValid: Subject<boolean> = new Subject<any>();
  getValidationErrors: Subject<any> = new Subject<any>();
  userLocale: any = Constants.EN;
  userDetailsSessionData: any;
  modelMsg:any;
  loading:any;
  data:any;
  locales:any;
  constructor( public router: Router, private translate: TranslateService, private http: HttpClient,private titleService: Title, private route: ActivatedRoute, private messageService: MessageService, private responseHandler: ResponseHandlerService,private apiCallingService: ApiCallingService,private confirmationService: ConfirmationService
  ) {
    this.getJSON().subscribe(headers => {
      this.errorMsgData = headers.RESPONCE_ERROR_MESSAGES;
    });
  }
  
  public getJSON(): Observable<any> {
    return this.http.get('../../assets/i18n/'+this.userLocale+'.json');
  }

  private getError(error: Response): Observable<any>{
      return Observable.throw(error.json() || 'Server Issue');
  }
  resetPassword(formData:any, apiEndPoint:any): Observable<any>{
    return this.apiCallingService.callAuthAPI(formData, Constants.requestType.PUT, apiEndPoint)
      .map(result => result)
      .catch(this.getError);
  }

  activeDeactivePipeUser(pipelineUser:any, changeUserStatusEndPoint:any): Observable<any>{
    return this.apiCallingService.callAuthAPI({}, Constants.requestType.PUT, changeUserStatusEndPoint)
      .map(result => result)
      .catch(this.getError);
  }  

  forceNumeric(e:any) {
        var key = e.which || e.keyCode;

        if (!e.shiftKey && !e.altKey && !e.ctrlKey &&
            // numbers
            key >= 48 && key <= 57 ||
            // Numeric keypad
            key >= 96 && key <= 105 ||
            // comma, period and minus, . on keypad
            key == 190 || key == 188 || key == 109 || key == 110 ||
            // Backspace and Tab and Enter
            key == 8 || key == 9 || key == 13 ||
            // Home and End
            key == 35 || key == 36 ||
            // left and right arrows
            key == 37 || key == 39 ||
            // Del and Ins
            key == 46 || key == 45)
            return true;

        return false;
    }

    public changeCurrencyFormat(number:any, id:any, locals: any) {
        if (!GeneralFunction.checkForNullUndefinedBlank(number) && number.indexOf(',') > -1) {
            number = number.replace(/,/g, '.');
        }
        if (!GeneralFunction.checkForNullUndefinedBlank(number) && !isNaN(number)) {
            var newNumber = number.replace(/\s/g, '');
            $('#' + id).val(Number(newNumber).toLocaleString(this.locales));
        }
    }
    // To Set converted values to ng-model
    public changeCurrencyFormatVal(number:any, locals: any) {
        if (!GeneralFunction.checkForNullUndefinedBlank(number) && number.indexOf(',') > -1) {
            number = number.replace(/,/g, '.');
        }
        if (!GeneralFunction.checkForNullUndefinedBlank(number) && !isNaN(number)) {
            var newNumber = number.replace(/\s/g, '');
            return Number(newNumber).toLocaleString(this.locales);
        }
    }


    public changeDotToComma(number:any, locals: any) {
        return Number(number).toLocaleString(this.locales);
    }
    // To Set converted values to datatable Purpose
    public changeCurrencyFormatValDatatable(number:any, locals=null) {
        if (!GeneralFunction.checkForNullUndefinedBlank(number)) {
            if (number.indexOf(' ') >= 0) {
                var newNumber = number.split(" ");
                var numberOnly = newNumber[0];
                var numberString = newNumber[1];

                if (numberOnly.indexOf(',') > -1) {
                    numberOnly = numberOnly.replace(/,/g, '.');
                }
                if (!GeneralFunction.checkForNullUndefinedBlank(numberOnly) && !isNaN(numberOnly)) {
                    var newNumber = numberOnly.replace(/\s/g, '');
                    return Number(newNumber).toLocaleString(this.locales) + ' ' + numberString;
                }
            } else {
                if (number.indexOf(',') > -1) {
                    number = number.replace(/,/g, '.');
                }
                if (!GeneralFunction.checkForNullUndefinedBlank(number) && !isNaN(number)) {
                    var newNumber = number.replace(/\s/g, '');
                    return Number(newNumber).toLocaleString(this.locales);
                }
            }
        }
    }
    public convertCommaTodot(number:any) {
        if (!GeneralFunction.checkForNullUndefinedBlank(number)) {
            number = number.replace(/\./g, '')
            return number = number.replace(/,/g, '.');
        }
        //return number = number.replace(/\s/g, '');
    }
    public convertIntoString(word: any) {
        if (word) {
            let string = word.toLowerCase();
            return string.charAt(0).toUpperCase() + string.slice(1);
        } else {
            return word;
        }
    } 
}
